
import FormItem from "@/shared/components/Forms/FormItem.vue";
import { AsYouType, CountryCode } from "libphonenumber-js";
import { computed, defineComponent, PropType } from "vue";
import { useVModel } from "vue-composable";
import { makePhoneNumberRule } from "@/shared/utils/validators/commonValidators";
import { useI18n } from "vue-i18n";
import { InfoCircleOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  inheritAttrs: false,
  props: {
    value: String,
    country: String as PropType<CountryCode>,
    validateFirst: Boolean,
  },
  setup(props) {
    const { t } = useI18n();

    const asYouTypeFormatter = computed(() => new AsYouType(props.country));

    const innerValue = useVModel(props, "value");
    const formattedValue = computed({
      get() {
        asYouTypeFormatter.value.reset();
        return asYouTypeFormatter.value.input(innerValue.value || "");
      },
      set(newValue: string) {
        asYouTypeFormatter.value.reset();
        asYouTypeFormatter.value.input(newValue);
        innerValue.value = asYouTypeFormatter.value.getChars();
      },
    });

    return { t, innerValue, formattedValue, makePhoneNumberRule };
  },
  components: {
    FormItem,
    InfoCircleOutlined,
  },
});
