import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InfoCircleOutlined = _resolveComponent("InfoCircleOutlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_FormItem = _resolveComponent("FormItem")!

  return (_openBlock(), _createBlock(_component_FormItem, {
    label: _ctx.$attrs.label,
    name: _ctx.$attrs.name,
    rules: [_ctx.makePhoneNumberRule()].concat(_ctx.$attrs.rules ?? []),
    extra: _ctx.$attrs.extra,
    help: _ctx.$attrs.help,
    validateFirst: _ctx.validateFirst,
    value: _ctx.innerValue,
    validateTrigger: _ctx.$attrs.validateTrigger
  }, {
    labelEnd: _withCtx(() => [
      _renderSlot(_ctx.$slots, "labelEnd")
    ]),
    default: _withCtx(() => [
      _createVNode(_component_a_input, _mergeProps(_ctx.$attrs, {
        placeholder: _ctx.$attrs.placeholder || _ctx.t('+63'),
        value: _ctx.formattedValue,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formattedValue) = $event)),
        autocomplete: "disabled",
        autocorrect: "disabled",
        autocapitalize: "disabled",
        spellcheck: "false"
      }), {
        prefix: _withCtx(() => [
          _renderSlot(_ctx.$slots, "prefix")
        ]),
        suffix: _withCtx(() => [
          _renderSlot(_ctx.$slots, "suffix", {}, () => [
            _createVNode(_component_a_tooltip, {
              title: _ctx.t('Use the following format: +[country code][your number]')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_InfoCircleOutlined)
              ]),
              _: 1
            }, 8, ["title"])
          ])
        ]),
        addonBefore: _withCtx(() => [
          _renderSlot(_ctx.$slots, "addonBefore")
        ]),
        addonAfter: _withCtx(() => [
          _renderSlot(_ctx.$slots, "addonAfter")
        ]),
        _: 3
      }, 16, ["placeholder", "value"])
    ]),
    _: 3
  }, 8, ["label", "name", "rules", "extra", "help", "validateFirst", "value", "validateTrigger"]))
}